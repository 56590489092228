
import { Vue, Options } from 'vue-class-component';
import { useMainStore } from '@/store/modules/main';
import Plane from '@/assets/images/plane.png'
import BackgroundPlane from '@/assets/images/background.jpg'

@Options({
  name: 'App',
})
export default class App extends Vue {
  store = useMainStore();
  public modalAirport = false;
  public Plane = Plane
  public BackgroundPlane = BackgroundPlane;

  get isActiveAirport() {
    return this.store.statusAirport
  }

  mounted() {
    this.store.getMenu()
    this.$watch("isActiveAirport", (value: boolean) => {
      if(value){
        this.loadModalAirport()
      }
    });
  }

  loadModalAirport() {
    const isSeeModal = localStorage.getItem('modalAirportV6');
    if (!isSeeModal) {
      this.modalAirport = true
    }
  }
  closeModalAirport(to = false) {
    this.modalAirport = false;
    localStorage.setItem('modalAirportV6', JSON.stringify({ value: true }))
    if (to) {
      this.$router.push("/airport")
    }
  }
}
